<!--
 * @Author: yangjinpeng
 * @Date: 2019-08-28 20:46:40
 * @LastEditors: lzp
 * @LastEditTime: 2021-10-13 16:12:44
 -->
<template>
  <div
    class="videoPage"
    style="position: relative;"
  >
    <Loading :loadingData="{isShow: opwloading}" />
    <div class="box_o">
      <div class="tit_o">
        <div class="tit_left">
          <span
            class="baidu_but baipishu"
            @click="baiduOpen('http://suanbanyun-app.oss-cn-beijing.aliyuncs.com/file/jepaas/JEPaaS%E4%BA%A7%E5%93%81%E7%99%BD%E7%9A%AE%E4%B9%A6.pdf')"
          >
            <i class="jeicon jeicon-data-dictionary" />
            <span>平台白皮书</span>
          </span>
          <!-- http://suanbanyun-app.oss-cn-beijing.aliyuncs.com/file/jepaas/JEPaaS%E4%BA%A7%E5%93%81%E7%99%BD%E7%9A%AE%E4%B9%A6.pdf -->
          <span
            class="baidu_but"
            @click="baiduOpen('https://pan.baidu.com/s/1HCrkWqwWcesG8xblvOF7dA')"
          >
            <img
              src="@/assets/imgs/video/baidu-cloud.png"
              alt=""
            >
            <span>教学视频网盘下载</span>
          </span>
          <span
            class="baidu_but"
            @click="baiduOpen('https://pan.baidu.com/s/1b_zB-TVGMnYfiNZGaD_rSw')"
          >
            <img
              src="@/assets/imgs/video/baidu-cloud.png"
              alt=""
            >
            <span>相关软件网盘下载</span>
          </span>
        </div>
        <div class="searchv">
          <span
            class="menu__entry"
            @click="menuVisible=true"
          >视频目录</span>
          <el-autocomplete
            ref="SearchAsync"
            v-model="state"
            :trigger-on-focus="false"
            :fetch-suggestions="querySearchAsync"
            placeholder="请输入内容"
            @select="handleSelect"
          />
          <span
            class="search_but"
            @click="goSearch"
          >搜索</span>
        </div>
      </div>
      <div
        v-if="bgData.img"
        class="box_bg"
        :style="{backgroundImage:'url(' + bgData.img + ')'}"
      >
        <div
          v-show="bgData.title"
          class="bg_tit"
        >
          {{ bgData.title }}
        </div>
        <div
          v-show="bgData.id"
          class="buttonShen"
          @click="goPlayer(bgData.id)"
        >
          立即观看
        </div>
      </div>
    </div>
    <div class="list_box">
      <div
        v-for="(all,index) in vidData"
        :key="index"
        class="list_o"
      >
        <div class="list_o_title">
          {{ all.typeOne }}
        </div>
        <div class="list_con">
          <div
            v-for="(all_,inde) in all.items"
            :key="inde"
            class="list_chart"
          >
            <div class="chart_ico">
              <span>
                {{ all_.title }}
              </span>
            </div>

            <div>
              <div class="chart_con">
                <div
                  class="img_box"
                  :style="{backgroundImage:'url('+ all_.items[0].imgTwo||loadingNun +')'}"
                  @click="goPlayer(all_.items[0].id)"
                />
                <div class="data_con">
                  <!-- <span class="data_tit">{{all_.items[0].title}}</span> -->
                  <!-- <p>{{all_.items[0].details}}</p> -->
                  <div class="p_con">
                    <p>{{ all_.items[0].details }}</p>
                  </div>
                  <span class="player_ico" />
                  <span class="player_num">{{ all_.items[0].playNum }}</span>
                </div>
              </div>
              <div class="lecture_con">
                <div
                  v-for="(butId, ind) in all_.items"
                  :key="ind+'but'"
                  class="but_con"
                  :class="{'left_con':ind==0}"
                >
                  <div
                    v-if="ind<6"
                    class="lecture_but"
                  >
                    <span
                      v-if="ind<5"
                      @click="goPlayer(butId.id)"
                    >第{{ numBig[ind] }}讲</span>
                    <span
                      v-if="ind>=5"
                      @click="goPlayer(all_.items[0].id)"
                    >更多>></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="menuVisible"
    >
      <i
        class="jeicon jeicon-error close"
        @click="menuVisible=false"
      />
      <el-collapse
        v-model="activeName"
        accordion
      >
        <el-collapse-item
          v-for="(item1, index1) in menuList"
          :key="index1"
          :title="item1.showTitle"
          :name="item1.showTitle"
        >
          <div class="list_wrp">
            <span
              v-for="(item2, index2) in item1.list"
              :key="index2"
              class="menu_items"
              @click="goPlayer(item2.id)"
            >
              {{ item2.title }}
            </span>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-dialog>
  </div>
</template>

<script>
import bg_su from '@/assets/imgs/successful/bg_su.png';
import loadingNun from '@/assets/imgs/loading.png';

export default {
  name: 'VideoPage',
  components: {

  },
  props: {},
  data() {
    return {
      state: '',
      opwloading: true,
      multiple: false,
      clearable: true,
      loading: false,
      bgData: [],
      optionsData: [],
      numBig: ['一', '二', '三', '四', '五'],
      vidData: [{
        id: 0,
        titleOne: '数据字典',
        listData: [
          // {
          //   id: 0,
          //   titleSecond: '资源表引擎',
          //   imgUrl: '',
          //   name: '资源引擎',
          //   details: '好视频好视频好视频好视频好视频',
          //   num: 123123,
          //   itemVid: '12:30',
          //   level: ['第一讲id', '第二讲id', '第三讲id', '第四讲id', '第五讲id', '第六讲id', '第七讲id']
          // }
        ],
      }],
      bg_su,
      loadingNun,
      menuVisible: false,
      activeName: '',
      menuList: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.optionsData = [];
    this.getData();
    this.getMenuList();
  },
  mounted() {

  },
  updated() { },
  methods: {
    querySearchAsync(queryString, cd) {
      const self = this;
      if (queryString.length < 2) return;
      self.$ajax({
        url: '/je/product/crm/mv/videoSearch',
        data: {
          input: queryString,
        },
      }).then((request) => {
        if (!request.data.success) return;
        let datas = [];
        if (!request.data.obj.info) {
          datas = [{
            value: '没有查询结果，请重新输入条件',
          }];
        } else {
          datas = request.data.obj.info.map(item => ({
            value: `${item.titleTwo}  ${item.title}`,
            id: item.id,
          }));
        }
        cd(datas);
      }).catch(() => {

      });
    },
    getMenuList() {
      const that = this;
      this.$ajax({
        url: '/je/product/crm/mv/videoHomeInfo',
        data: {
          type: 'top',
        },
      }).then((res) => {
        let list = [];
        if (res.data && res.data.success) {
          if (res.data.obj.vidData instanceof Array && res.data.obj.vidData.length > 0) {
            res.data.obj.vidData.forEach((item) => {
              if (list.length < 1) {
                list = item.listData;
              } else {
                list.concat(item.listData);
              }
            });
          }
          list.forEach((item) => {
            const obj = {
              showTitle: item[0].typeTwo,
              list: item,
            };
            if (that.menuList.length < 1) {
              that.menuList[0] = obj;
            } else {
              that.menuList.push(obj);
            }
          });
          that.activeName = that.menuList[0].showTitle;
        }
      });
    },
    handleSelect(item) {
      const self = this;
      if (item.id) {
        self.goPlayer(item.id);
      }
    },
    goSearch() {
      const self = this;
      self.$refs.SearchAsync.focus();
    },
    baiduOpen(e) {
      window.open(e);
    },
    goPlayer(e) {
      const self = this;
      self.$router.push({
        name: 'playerPage',
        query: {
          id: e,
        },
      });
    },
    getData() {
      const self = this;
      self.$ajax({
        url: '/je/product/crm/mv/videoHomeImg',
        data: {},
      }).then((request) => {
        const data = request.data;
        if (!data.success) return;
        self.bgData = data.obj;
      }).catch(() => {

      });
      self.$ajax({
        url: '/je/product/crm/mv/videoHomeInfo',
        data: {},
      }).then((request) => {
        const data = request.data;
        if (!data.success) return;
        const items = data.obj.vidData.map((item) => {
          const _items = item.listData.map((_item) => {
            const title = _item.length ? _item[0].typeTwo : '';
            return { title, items: _item };
          });
          return { typeOne: item.typeOne, items: _items.filter(_item => _item.items.length) };
        });
        self.vidData = items;
        self.opwloading = false;
      }).catch(() => {

      });
    },
  },
};
</script>
<style lang="less">
.searchv {
  text-align: center;
  display: flex;
  > .menu__entry {
    width:120px;
    height:38px;
    background:#FF3041;
    border-radius:19px;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
    font-size: 16px;
    display: inline-block;
    margin-right: 30px;
    cursor: pointer;
    transition: all 300ms;
    &:hover {
      background: #e52b3a;
    }
  }
  .el-select .el-input__inner {
    cursor: auto;
  }
  .el-input {
    border: 1px solid red;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    height: 36px;
  }
  input {
    cursor: auto;
    border: 0;
    width: 345px;
    height: 36px;
    font-size: 14px !important;
    border-top-left-radius: 23px;
    border-bottom-left-radius: 23px;
    color: #666;

    background-color: rgba(238, 238, 238, 0.3);
  }
  .search_but {
    cursor: pointer;
    border: 0;
    width: 90px;
    height: 38px;
    background: rgba(255, 48, 65, 1);
    border-top-right-radius: 23px;
    border-bottom-right-radius: 23px;
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 254, 254, 1);
    line-height: 40px;
  }
}
</style>
<style lang="less">
  .videoPage {
    text-align: center;
    min-height: calc(100vh - 290px - 80px);
    .el-dialog {
      width: 1200px;
      .close {
        position: absolute;
        font-size: 18px;
        top: 30px;
        right: 30px;
        font-size: #999999;
        font-weight: normal;
        cursor: pointer;
      }
      .el-dialog__header {
        display: none;
      }
      .el-dialog__body {
        padding: 60px 50px;
        height: 800px;
        box-sizing: border-box;
        overflow-y: hidden;
        * {
          border: 0;
        }
        .el-collapse {
          overflow-y: auto;
          height: 100%;
        }
        .el-collapse-item__header {
          background: #f8f8f8;
          margin-top: 10px;
          height: 70px;
          padding-left: 30px;
          font-size: 20px;
          color: #333333;
          border-radius: 4px;
          font-weight: normal;
          &.is-active {
            background: #FF3041;
            color: #ffffff;
          }
          &:not(:first-child) {
            margin-top: 10px;
          }
          .el-collapse-item__arrow {
            color: #FF3041;
            margin-right: 40px;
            transform: rotate(-90deg);
            &.is-active {
              color: #ffffff;
              transform: rotate(90deg);
            }
          }
        }
        .el-collapse-item__content {
          padding: 0;
        }
        .list_wrp {
          padding: 15px 20px 30px 10px;
          background: #f8f8f8;
          border-radius: 4px;
          .menu_items {
            float: left;
            box-shadow:0px 5px 20px 0px rgba(0, 0, 0, 0.1);
            border-radius: 18px;
            padding: 5px 20px;
            color: #777777;
            font-size: 16px;
            margin: 15px 0 0 10px;
            transition: all 300ms;
            background: #ffffff;
            cursor:pointer;
            &:hover {
              background: #FF3041;
              color: #ffffff;
            }
          }
          &:after {
            content: '';
            display: table;
            clear: both;
          }
        }
      }
    }
  }
</style>
<style lang="less" scoped>
.videoPage {
  text-align: center;
  .buttonShen {
    cursor: pointer;
    width: 150px;
    margin: 0px auto;
    height: 50px;
    background: rgba(255, 48, 65, 1);
    box-shadow: 2px 2px 10px 0px rgba(252, 44, 54, 0.4);
    border-radius: 30px;
    line-height: 50px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(254, 255, 255, 1);
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 44%;
    &:after {
      content: "";
      width: 150px;
      height: 50px;
      position: absolute;
      top: 0px;
      left: 0px;
      border-radius: 30px;
      z-index: -1;
      transition: background 0.7s;
    }
    &:hover:after {
      z-index: 2;
      background: rgba(51, 51, 51, 0.1);
    }
  }
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .box_o {
    width: 1200px;
    margin: auto;
    .tit_o {
      height: 86px;
      font-size: 22px;
      font-weight: 500;
      color: rgba(51, 51, 51, 1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .tit_left {
        display: flex;
        align-items: center;
        .baidu_but {
          cursor: pointer;
          margin-left: 20px;
          height: 32px;
          background-color: #ebebeb;
          border-radius: 6px;
          font-size: 14px;
          font-weight: 400;
          color: rgba(51, 51, 51, 1);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: boder-color 0.5;
          padding: 0 10px;
          img {
            padding: 0 2px;
            width: 25px;
            height: 20px;
          }
        }
        .baipishu {
          background-color: #F54C4D;
          color: #fff;
          .jeicon {
            font-size: 14px;
            margin-right: 5px;
          }
          &:hover {
            background-color: #fff;
            color: #F54C4D;
          }
        }
      }
    }
    .box_bg {
      position: relative;
      width: 1200px;
      height: 320px;
      background: rgba(184, 184, 184, 1);
      background-size: cover;
      text-align: left;
      .bg_tit {
        height: 62px;
        font-size: 64px;
        font-weight: bold;
        color: rgba(255, 255, 255, 1);
        padding: 88px 0 0 67px;
      }
    }
  }
  .list_box {
    width: 1200px;
    margin: auto;
    padding-bottom: 80px;
    .list_o {
      .list_o_title {
        font-size: 18px;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
        text-align: left;
        padding-top: 20px;
      }
      .list_con {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .list_chart {
          margin-top: 20px;
          width: 590px;
          height: 300px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 3px 5px 0px rgba(221, 221, 221, 0.75);
          .chart_ico {
            text-align: left;
            & > span {
              padding: 10px 20px;
              height: 44px;
              line-height: 44px;
              background: rgba(255, 48, 65, 1);
              border-radius: 0px 22px 22px 0px;
              font-size: 16px;
              font-weight: 500;
              color: rgba(255, 255, 255, 1);
              overflow: hidden;
              text-overflow: ellipsis;
              // display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
            }
          }
          .chart_con {
            padding: 20px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            .img_box {
              cursor: pointer;
              width: 230px;
              height: 160px;
              background: rgb(165, 113, 113);
              background-size: cover;
              position: relative;
              &:before {
                content: "";
                display: block;
                width: 230px;
                height: 160px;
                z-index: -1;
                background: rgba(0, 0, 0, 1);
                opacity: 0;
                transition: opacity 0.7s;
              }
              &:hover:before {
                z-index: 2;
                opacity: 0.65;
              }
              &:after {
                content: "";
                display: block;
                width: 38px;
                height: 38px;
                background-image: url(../../../../assets/imgs/video/video.png);
                background-color: transparent;
                background-size: cover;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-19px, -19px);
                opacity: 0;
                transition: opacity 0.7s;
              }
              &:hover:after {
                opacity: 0.65;
              }
              & > div {
                padding: 0px 8px;
                background: rgba(27, 27, 27, 1);
                opacity: 0.5;
                border-radius: 12px;
                font-size: 14px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                line-height: 24px;
                position: absolute;
                right: 10px;
                bottom: 10px;
              }
            }
            .data_con {
              width: 271px;
              text-align: left;
              .data_tit {
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
              }
              .p_con {
                height: 70px;
              }
              p {
                font-size: 14px;
                font-weight: 400;
                color: rgba(51, 51, 51, 1);
                line-height: 21px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
              }
              .player_ico {
                display: inline-block;
                width: 20px;
                height: 20px;
                text-align: center;
                line-height: 15px;
                background: rgba(160, 160, 160, 1);
                border-radius: 50%;
                &:after {
                  content: " ";
                  display: inline-block;
                  width: 0px;
                  height: 0px;
                  margin-left: 2px;
                  border-top: 5px solid transparent;
                  border-bottom: 5px solid transparent;
                  border-left: 8px solid #fff;
                }
              }
              .player_num {
                padding-left: 10px;
                width: 100px;
                font-size: 12px;
                font-weight: 400;
                color: rgba(153, 153, 153, 1);
                line-height: 21px;
                overflow: hidden;
              }
            }
          }
          .lecture_con {
            padding: 10px 30px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .but_con {
              width: 76px;
              height: 30px;
              margin-left: 15px;
            }
            .left_con {
              margin-left: 0px;
            }
            .lecture_but {
              cursor: pointer;
              line-height: 30px;
              border: 1px solid rgba(153, 153, 153, 1);
              border-radius: 6px;
              font-size: 14px;
              font-weight: 400;
              color: rgba(153, 153, 153, 1);
              transition: background 0.7s;
              &:hover {
                background: rgba(255, 48, 65, 1);
                color: rgb(255, 255, 255);
                border: 1px solid rgba(255, 48, 65, 1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
